
































































import {Component, Ref, Vue} from "vue-property-decorator"
import {getModule} from "vuex-module-decorators"
import DialogModule from "@/store/DialogModule"
import Dialog from "@/models/vue/Dialog"
import Promo from "@/models/Promo"
import PromoService from "@/services/PromoService"
import LangModule from "@/store/LangModule"
import Catalogue from "@/models/Catalogue"
import CatalogueService from "@/services/CatalogueService"
import StringTool from "@/services/tool/StringTool"
import FilePickerComponent from "@/components/FilePickerComponent.vue"
import BannerService from "@/services/BannerService";

@Component({ components: {FilePickerComponent} })
export default class CatalogueView extends Vue {
  lang: any = getModule(LangModule).lang
  @Ref() readonly form!: HTMLFormElement
  dialog: boolean = false
  catalogue: Catalogue = new Catalogue()
  catalogues: Catalogue[] = []

  promos: Promo[] = []
  promo: Promo = new Promo()
  page: number = 1
  pageCount: number = 0
  itemsPerPage: number = 10
  loading: boolean = false
  search: string = ""
  active: boolean = false
  headers = [
    {align: 'center', text: 'ID', value: "id", width: "auto"},
    {align: 'center', text: this.lang.name, value: "name", width: "auto"},
  ]
  file: File | null = null
  rulesFile = [(v: any) => v ? true : this.lang.documentRequired]

  rules = {
    required: (input: string) => (input ? true : this.lang.requiredField),
    email: (input: string) => StringTool.validateEmail(input) ? true : this.lang.emailNoValid,
    postalCode: (input: string) => StringTool.validatePostalCode(input) ? true : this.lang.invalidPostalCode,
  }

  created() {
    this.refresh()
  }

  async refresh() {
    await CatalogueService.getCatalogue(this, Number(this.$route.params.id))
  }

  async updateCatalogue() {
    await CatalogueService.updateCatalogue(this, Number(this.$route.params.id), this.catalogue)
  }

  activateCatalogue() {
    getModule(DialogModule).showDialog(new Dialog(
        this.lang.warning, "¿Esta seguro de cambiar el estado del catalogo?",
        async () => await CatalogueService.activateCatalogue(this, Number(this.$route.params.id), this.catalogue.active!)
    ))
  }

  downloadDocument() {
    let link = document.createElement("a")
    link.href = this.catalogue.document!!.url!!
    link.target = "_blank"
    link.download = "filename"
    link.click();
  }

  openUrl(url: string) {
    window.open(url, "_blank")
  }
}
